$blue: #004b88;
$green: #208800;
$lightblue: #00e4ff;
$grey1: #e0e0e0;
$grey2: #bbb;
$grey3: #424242;

@font-face {
    font-family: 'Uni0553';
    src: url('/assets/fonts/uni0553-webfont.eot');
    src: url('/assets/fonts/uni0553-webfont.eot#iefix') format('embedded-opentype'),
         url('/assets/fonts/uni0553-webfont.woff') format('woff'),
         url('/assets/fonts/uni0553-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
  font-family: 'VT323', monospace;
  font-size: 24px;
  letter-spacing: 1.2;
  background-color: $grey1;
}

a {
  text-decoration: none;
  color: $blue;
}

h1.title {
  font-family: 'Uni0553', monospace;
  font-size: 64px;
  margin: 28vh 0 0;
  text-align: center;

  a {
    margin: 0 auto;
    font-variant: small-caps;
    text-shadow: $grey2 4px 4px;
  }
}

// TODO: Add a "back" link to title

.subtitle {
  display: flex;
  font-size: 24px;
  flex-direction: column;
  text-align: center;
  color: $grey3;
  margin-bottom: 2em;
}

ul.links {
  font-size: 32px;
  max-width: 480px;

  display: flex;
  flex-wrap: wrap;

  margin: 0 auto;
  text-align: center;

  li {
    list-style: none;

    a:hover, a:focus {
      color: $green;
    }
  }

  &.contact, &.bottom {
    flex-direction: column;
    padding: .5em 1em;
  }
}

.nav {
  padding: .5em 1em;

  justify-content: space-between;
}

a.cursor {
  display: inline-block;
  padding: .5em;

  &:after {
    content: "|";
    opacity: 0;
  }

  &:hover, &:focus {
    &:after {
      opacity: 1;
      animation: blink 1s step-end infinite;
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
